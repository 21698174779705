<template>
  <div class="bracketsWrapper">
    <nav class="brackets-nav">
      <ul>
        <router-link tag="li" active-class="active" exact-active-class="active" :to="{ name: 'groups'}" v-if="TOURNAMENT.attributes.group_type">Группы</router-link>
        <router-link tag="li" active-class="active" exact-active-class="active" :to="{ name: 'playoff'}" v-if="TOURNAMENT.attributes.playoff_type">Плей-офф</router-link>
      </ul>
    </nav>
    <transition name="default">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "tournamentBrackets",
  computed: {
    ...mapGetters([
        'TOURNAMENT'
    ])
  }
}
</script>

<style lang="scss" scoped>
.bracketsWrapper {
  overflow: hidden;
  //padding-left: 16px;
  //padding-right: 16px;
  padding-bottom: 90px;
  @media (max-width: 525px) {
    padding-left: 0;
    padding-right: 0;
    .container {
      max-width: unset;
    }
  }
}
</style>
